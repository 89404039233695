@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.instances-table-container {
  margin-left: -16px;
  margin-top: -16px;
  background-color: $ui-background;
  .application-deployments-description {
    @include carbon--type-style('body-short-01');
    display: flex;
    padding: 16px 16px 24px 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    color: var(--Text-text-secondary, #525252);
  }

  .gateway-field {
    @include carbon--type-style('body-short-01');
    color: $gray-100;
    a {
      @include carbon--type-style('body-short-01');
      color: $gray-100;
      &:hover {
        color: $blue-60;
        text-decoration: none;
      }
    }
  }

  .appDeployment-field {
    color: $gray-60;
    @include type-style('label-01');
    a {
      color: $gray-60;
      @include type-style('label-01');
      &:hover {
        color: $blue-60;
        text-decoration: none;
      }
    }
  }

  .bx--table-toolbar {
    display: block;
  }

  .bx--data-table td {
    padding: 15px 16px;
  }
}
