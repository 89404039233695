@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.edit-external-service-tearsheet {
  .edit-service-form {
    padding-top: $spacing-06;
    padding-bottom: $spacing-09;
    .section {
      padding-bottom: $spacing-05;
    }
    .section-header {
      @include carbon--type-style('productive-heading-03');
      margin-bottom: $spacing-06;
      
    }

    .row, .add-labels-component {
      margin-bottom: $spacing-07;
    }

    .endpoints-header {
      @include carbon--type-style('productive-heading-02');
      margin-bottom: $spacing-05;
    }

    .endpoints-table {
      background-color: white;
      padding: $spacing-05;
    }
  }
}