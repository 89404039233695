@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.landing-page-container {
  background-color: $ui-01;
  padding: 0;
  overflow-x: hidden;
  height: 100%;

  .banner-container {
    position: relative;
    display: flex;
    min-height: 3rem;
    background-color: $ui-background;
    border-bottom: 1px solid $decorative-01;

    .hero-image {
      display: flex;
      width: 40%;
      justify-content: center;
      align-items: center;
      img {
        // position: absolute;
        // right: 0;
        // width: auto;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .banner-content {
      width: 60%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: 1056px) {
      .banner-content {
        width: 100%;
      }
      .hero-image {
        display: none;
      }
    }

    .header {
      @include carbon--type-style('productive-heading-05');
      position: relative;
      z-index: 10;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0px;
    }

    .subheader {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      color: $text-02;
      padding-top: 0.5rem;
    }

    .sections {
      margin-top: 4rem;
      margin-bottom: 1rem;
      display: flex;

      .subheader {
        @include carbon--type-style('productive-heading-01');
        margin-bottom: 0.75rem;
      }

      .text {
        @include carbon--type-style('body-short-01');
        margin-bottom: 1.25rem;
        color: $text-secondary;
      }

      .section {
        margin-right: 35px;

        @media screen and (max-width: 672px) {
          margin-right: $spacing-06;
        }
      }

      .get-started-doc-link {
        display: flex;
        align-items: center;
      }

      .section-link {
        @include carbon--type-style('body-short-01');
        text-decoration: none;
        color: $link-primary;
        cursor: pointer;

        .link-text {
          margin-right: $spacing-03;
        }
        .link-icon {
          padding: $spacing-01 0;
          max-height: 18px;
        }
      }
    }
  }

  .banner-expander {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 3rem;
    .cds--popover--top .cds--popover-content {
      top: 0;
      left: -50%;
    }
  }

  .close-banner {
    position: absolute;
    right: 3rem;
    height: 3rem;
  }

  .banner-expander,
  .close-banner {
    path {
      fill: $ui-01 !important;
    }
    .bx--btn--ghost:hover {
      .bx--assistive-text {
        display: none;
      }
    }
  }
}

.collapsed-banner {
  display: flex;
  align-items: center;
  .welcome {
    @include carbon--type-style('body-long-01');
    margin-left: 1rem;
  }

  .close-banner,
  .banner-expander {
    path {
      fill: $icon-primary !important;
    }
  }

  }



.bx--progress-label {
  width: fit-content;
  max-width: unset;
}

.landing-page-content {
  background-color: $ui-01;
  padding: 0.5rem;

  .empty-topology-state {
    background-color: $ui-02;
    display: flex;
    flex-direction: column;
    flex: calc(50% - 1rem);
    margin: 0.5rem;
    padding: 1rem;
    min-height: 17.25rem;

    .card-header {
      @include carbon--type-style('productive-heading-02');
    }

    .empty-state {
      margin: auto 0;
    }
  }

  @media screen and (max-width: 671px) {
    max-height: unset;
  }

  .topology-card {
    background-color: $ui-02;
    display: flex;
    margin: 0.5rem;
    max-height: 18.25rem;
    padding-left: $spacing-09;

    @media screen and (max-width: 1056px) {
      padding-left: $spacing-05;
      padding-right: $spacing-05;
    }

    @media screen and (max-width: 950px) {
      padding: 4.375rem 0;
      max-height: none;
      height: auto;
    }

    .text {
      // margin: auto 0;
      padding-left: 8.5rem;
      max-width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 1056px) {
        width: calc(50% - 106px);
        padding-left: 6rem;
      }

      @media screen and (max-width: 950px) {
        max-width: 100%;
        width: 100%;
        padding-left: $spacing-07;
        padding-right: $spacing-05;
      }
    }

    .image {
      width: calc(50% - 24px);
      display: flex;

      @media screen and (max-width: 1056px) {
        width: calc(50% + 40px);
      }

      @media screen and (max-width: 950px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        padding: 1.5rem 0 0 0;
      }
    }

    .header {
      @include carbon--type-style('productive-heading-04');
      margin-bottom: 0.5rem;
    }

    .description {
      margin-bottom: 2rem;
      //width:60%;
    }
  }

  .topology-card-skeleton {
    min-height: 18.25rem;
    margin: 0.5rem;
    max-width: 100%;
    width: auto;
  }

  .resource-tiles {
    display: flex;
    flex-wrap: wrap;

    .overview-tile,
    .overview-tile-skeleton {
      flex: calc(50% - 1rem);
      margin: 0.5rem;
      @media screen and (max-width: 672px) {
        flex: 100%;
      }
    }
  }
}

.notification-container {
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .cds--inline-notification {
    min-width: 100%;
  }
  
  .cds--actionable-notification {
    min-width: 100%;
    .cds--actionable-notification__action-button.cds--btn--ghost {
      margin-top: 1.6rem;
    }
  } 
}
