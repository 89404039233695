@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.service-tile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid $gray-20;

  .service-name {
    color: $gray-100;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;

    .action-buttons {
      display: none;
    }
  }

  &:hover {
    background-color: $background-hover;

    .action-buttons {
      display: flex;
      cursor: pointer;
      padding-left: 30rem;

      .edit-svg {
        margin-right: $spacing-06;
      }

      .edit-link,
      .delete-link {
        color: black;
        cursor: pointer;
      }

      .tooltipDeleteService {
        .bx--tooltip__trigger::before {
          top: 5px;
          left: -11px;
          transform: rotate(-90deg);
        }
        .delete-link.disabled {
          cursor: not-allowed;
        }
        .bx--assistive-text {
          left: -250px;
          top: 40px;
        }
      }
    }
  }

  .service-body {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 6rem;

    .port-pairs {
      display: flex;
      width: 142px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .port-label {
        color: $text-02;

        font-size: 12px;

        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.32px;

        .port-value {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;

          .port-number {
            color: $gray-100;
            text-align: center;

            font-size: 28px;
            line-height: 36px;
          }

          .protocol-spacing {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.16px;
          }
          .ports-spacing {
            border-right: 1px solid $gray-30;
            padding-right: 4px;
          }
        }
      }
    }

    .endpoint-pair {
      display: flex;
      width: 142px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .endpoint-header {
        color: $text-02;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.32px;
      }

      .endpoint-count {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        .total-count {
          color: $gray-100;
          text-align: center;
          font-size: 28px;
          line-height: 36px;
        }

        .external-cluster-count {
          color: $text-02;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.16px;

          .external-count {
            border-right: 1px solid $gray-30;
            padding-right: 4px;
          }
        }
      }
    }

    .event-error-pair {
      display: flex;
      width: 196px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .event-error-header {
        color: $text-02;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.32px;
      }

      .event-error-count {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        .total-count {
          color: $gray-100;
          text-align: center;
          font-size: 28px;
          font-style: normal;
          line-height: 36px;
          text-decoration: unset;
          &:hover {
            color: #0f62fe;
            text-decoration: unset;
          }
        }

        .critical-major-count {
          display: inline;
          align-items: flex-start;
          gap: 10px;
          color: $text-02;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.16px;
          img {
            height: $spacing-04;
            width: $spacing-04;
          }

          .critical-count {
            border-right: 1px solid $gray-30;
            padding-right: 4px;
          }
        }
      }
    }
  }

  .service-labels {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 69;
    height: 20;

    .label-header {
      color: $text-02;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
    }

    .label-body {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
