@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.deployments-table-container {
  .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  margin-top: $spacing-05;
  width: 100%;
  background-color: $ui-background;

  .bx--pagination {
    background-color: $ui-background;

    .bx--select-input {
      background-color: $ui-background;
    }
  }

  .bx--data-table-content {
    overflow: visible; // needed to prevent tooltip from getting cut off
  }

  .header-tooltip {
    button {
      @include carbon--type-style('productive-heading-01');
      color: $text-01 !important;
    }
  }

  td,
  .bx--table-toolbar {
    .bx--toolbar-content .dropdown .bx--label {
      padding-left: 1rem;
    }

    .bx--toolbar-content .bx--dropdown {
      background-color: $white-0;
      margin-left: 4px;
    }
  }

  .bx--data-table td {
    padding: 15px 16px;
  }

  tr {
    .depl-env-link {
      color: unset;
      text-decoration: unset;
    }

    .register-endpoint-link button {
      @include carbon--type-style('body-short-01');
      color: $link-primary;
    }
  }

  tr:hover {
    .depl-env-link {
      color: $link-primary;
    }
    .bx--tooltip__trigger {
      color: $hover-primary-text;
      cursor: pointer;
    }
  }

  .depl-env-link {
    &:hover {
      text-decoration: underline;
    }
  }

  .deployments-header {
    display: flex;
    padding: $spacing-05;
    @include carbon--type-style('productive-heading-03');
  }
  .deployments-header.border {
    border-bottom: 1px solid $ui-03;
  }

  .bx--search-input {
    border-bottom: unset;
  }

  .auto-discovery-row {
    padding-left: $spacing-05;
    align-items: center;
    justify-content: space-between;
  }

  .gateway-link {
    text-decoration: unset;
    &:hover {
      text-decoration: underline;
    }
  }
  .gateway-field {
    @include carbon--type-style('body-short-01');
    color: $gray-100;
    a {
      @include carbon--type-style('body-short-01');
      color: $gray-100;
      &:hover {
        color: $blue-60;
        text-decoration: none;
      }
    }
  }

  .appDeployment-field {
    color: $gray-60;
    @include type-style('label-01');
    a {
      color: $gray-60;
      @include type-style('label-01');
      &:hover {
        color: $blue-60;
        text-decoration: none;
      }
    }
  }
}
// overflow menu options should always expand
.bx--overflow-menu-options {
  width: unset !important;
}
