@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.application-details-container {
  //Made notification sticky
  .inline-notification-container {
    position: sticky;
    top: 50px;
    z-index: 7000;
  }

  .application-details-section {
    background-color: $ui-01;
    padding: 1rem;

    .tooltipDeleteApplication {
      margin-top: $spacing-05;
      margin-bottom: 4rem;
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: 0px;
    }

    .deployments-table-container {
      margin-left: -16px;
      margin-top: -16px;

      .application-deployments-description {
        @include carbon--type-style('body-short-01');
        display: flex;
        padding: 16px 16px 24px 16px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        color: var(--Text-text-secondary, #525252);
      }

      .bx--search-input::placeholder {
        color: transparent;
      }

      .bx--toolbar-search-container-expandable:hover {
        background: $ui-background;
      }

      .bx--toolbar-search-container-active
        .bx--search-input:not(:placeholder-shown) {
        background: $ui-background;
      }

      .bx--toolbar-search-container-expandable.bx--search
        .bx--search-close::before {
        background-color: $ui-background;
      }

      .bx--toolbar-search-container-expandable.bx--search
        .bx--search-close:hover {
        .bx--toolbar-search-container-expandable.bx--search
          .bx--search-close::before {
          background-color: $hover-ui;
        }

        background-color: $hover-ui;
        border-bottom: 1px solid $disabled-03;
      }
    }

    .delete-service-modal {
      .bx--modal-container {
        width: 35rem;
        height: 13.25rem;
      }
    }

    .delete-application-modal {
      .bx--modal-container {
        width: 35rem;
        height: 13.25rem;
      }
    }

    .applicationDetailsDeleteDisabled {
      border-color: transparent;
      background: transparent;
      color: #c6c6c6;
      outline: none;
      cursor: not-allowed;
      box-shadow: none;
    }

    .applicationDetailsDelete {
      margin-top: 1rem;
    }

    .details-tile .data-container .value .bx--tag {
      margin-left: 0;
      margin-right: $spacing-04;
    }

    .bx--tabs--scrollable.bx--tabs--scrollable--container
      .bx--tabs--scrollable__nav-item
      .bx--tabs--scrollable__nav-link {
      width: auto;
    }
  }

  .value {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tiles {
    display: flex;
    margin-bottom: 1rem;

    .bx--tile {
      flex: 1;
    }

    .details-tile {
      margin-right: 0.5rem;
    }

    .services-tile {
      margin-left: 0.5rem;
    }
  }

  .app-details-skeleton {
    width: 50%;
    height: 24.5rem;
    margin-right: 1rem;
  }

  .deployments-section {
    background-color: white;

    .text {
      @include carbon--type-style('productive-heading-03');
    }

    .empty-depl-state {
      margin-left: 25%;
    }

    .deployment-button-skeleton {
      width: 45rem;
      margin-top: 5rem;
      margin-left: 95.25rem;
    }

    .deployment-table-skeleton {
      width: 99%;
    }
  }
}
