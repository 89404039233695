@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.progressStepper {
  margin: 1.5rem;
  background-color: $ui-background;

  .progressHeader {
    padding: 1rem;
    background-image: linear-gradient(to right, #001D6C , #6929C4);
    color: $white-0;
  }

  .progressNotification {
    max-width: 100%;
    width: auto;
    margin: 1rem;
    margin-bottom: 0;
  }

  .progressBody {
    
    .sections {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-left: 1rem;
      display: flex;
      font-size: 0.875rem;

      .icon {
        margin-bottom: 1rem;

        img {
          height: 24px;
          width: 24px;
        }
      }

      .step {
        margin-bottom: 0.75rem;
      }

      .link {
        color: $link-primary;

        a {
          padding: 0;
          margin: 0;
        }

        img {
          filter: brightness(0) saturate(100%) invert(34%) sepia(83%) saturate(6069%) hue-rotate(218deg) brightness(103%) contrast(99%);
        }
      }

      .link-dis {
        img {
          filter: brightness(0) saturate(100%) invert(86%) sepia(0%) saturate(1092%) hue-rotate(171deg) brightness(93%) contrast(93%);
        }
      }

      .section {
        margin-right: 2rem;

        @media screen and (max-width: 672px) {
          margin-right: 2rem;
        }
      }
    }
  }
  .walkme-modal {
    .bx--modal-container {
      background-color: #161616;

      .bx--modal-header {
        display: none;
      }

      .bx--modal-content {
        padding: 0;
        color: $text-04;


        .modal-title {
          font-size: 28px;
          line-height: 36px;
          letter-spacing: 0px;
          margin: 1rem;
        }

        .modal-text {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.16px;
          margin: 1rem;
        }

        .modal-subtitle {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.16px;
          margin: 1rem;
          margin-bottom: 0;
        }

        .modal-checklist {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0px;
          margin: 0.5rem 1rem;
          margin-bottom: 0;

          svg {
            fill: #58e5e5;
            margin-right: 0.5rem;
            position: relative;
            top: 3px;
          }
        }

        .walkme-modal-img {
          width: 100%;
        }
      }

      
    }

    
  }
}