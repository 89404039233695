@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.register-external-service-tearsheet {
  .register-external-service-form {
    padding-top: $spacing-06;
    padding-bottom: $spacing-09;
    .section {
      margin-bottom: $spacing-05;
    }

    .section-header {
      @include carbon--type-style('productive-heading-03');
      margin-bottom: $spacing-03;
    }
    .section-subheader {
      @include carbon--type-style('body-long-01');
      color: $text-02;
      margin-bottom: $spacing-06;
    }

    .form-item {
      margin-bottom: $spacing-07;
      .form-item-header {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-02;

        .info-tooltip svg {
          fill: $text-02;
        }
      }

      .section-header.endpoints {
        @include carbon--type-style('productive-heading-02');
      }
    }

    .network-segment-dropdown {
      padding-bottom: $spacing-03;
    }

    .app-name-input {
      margin-top: $spacing-05;
    }

    .toggletip-svc-name {
      inset-block-start: 0;
    }

    .service-name-tooltip-content {
      a {
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .additional-info-sections {
      margin-top: $spacing-06;

      .expanded-section {
        padding-top: $spacing-03;
        padding-bottom: $spacing-06;

        .add-labels-component,
        .text-area,
        #rg-dropdown {
          margin-bottom: $spacing-07;
        }
      }
    }
  }
}
