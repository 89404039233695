@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.add-svc-endpoint-component {
  .endpoint-row {
    padding-left: $spacing-05;
    margin-bottom: $spacing-05;
  }
  .input-label {
    margin-bottom: $spacing-03;
    position: relative;

    .info-tooltip {
      position: absolute;
      margin-left: $spacing-02;
    }
  }
  .delete-button-column {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2px;
  }

  #targets-input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-wrapper {
    position: relative;

    #targets-input-edit-mode {
      z-index: 99999;
      position: absolute;
      height: unset;
    }
  }

  .add-endpoint-row {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-05;
  }
}
